import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarTop from '../navbar/adm/top/NavbarTop';
import Footer from '../footer/Footer';

const MainLayoutAdm = () => {
    const { hash, pathname } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        }, 0);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className={'container-fluid'}>
            {/* <NavbarVertical /> */}
            <div className={'content'}>
                <NavbarTop />
                <Outlet />
                <Footer />
            </div>
        </div>
    );
};

export default MainLayoutAdm;
