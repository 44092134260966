import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import NavTecs from './NavTecs';
import Charts from './Charts';
import axios from 'axios';
import moment from 'moment';

const DashboardAdm = () => {

    const [clima, setClima] = useState({});
    const [tec, setTec] = useState('');
    const [temp, setTemp] = useState('');
    const [time, setTime] = useState('');
    const [irrad, setIrrad] = useState('');

    useEffect(() => {
        setTemp(clima.temp ?? 19);
        setIrrad(clima.ghi ?? 200);
        setTime(clima.time ?? moment().format('HH:mm'));
    }, [clima]);

    useEffect(() => {
        busca();
        // eslint-disable-next-line
    }, [tec]);

    useEffect(() => {
        if (!tec && !temp && !time && !irrad) {
            const clima = verifyClimaLocal();
            if (!clima) {
                if ('geolocation' in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        position => {
                            const lat = position.coords.latitude;
                            const lon = position.coords.longitude;
                            getWeatherData(lat, lon);
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } else {
                    console.log('Não tem suporte');
                }
            } else {
                setClima({ ...clima });
            }
        }
        // eslint-disable-next-line
    }, []);

    const getWeatherData = async (lat, lon) => {
        try {
            // https://www.weatherbit.io/api/weather-current
            const data = await axios.get(`https://api.weatherbit.io/v2.0/current?lat=${lat}&lon=${lon}&key=25cd1358930e459b86956976a6c53c63`);
            const dados = data?.data?.data[0] ?? {};
            setClima(dados);
            verifyClimaLocal(dados);
        } catch (error) {
            console.log(error);
        }
    };

    const verifyClimaLocal = (clima = false) => {
        try {
            if (!clima) {
                clima = localStorage.getItem('clima');
                if (clima) clima = JSON.parse(clima);
                if (!clima || !clima?.city_name) return false;
            } else if (clima && clima.city_name) {
                localStorage.setItem('clima', JSON.stringify(clima));
            }
            return clima;
        } catch (error) {
            localStorage.removeItem('clima');
            return false;
        }
    }

    const busca = () => {
        clima.temp = temp;
        clima.ghi = irrad;
        clima.time = time;
        clima.tec = tec;
        setClima({ ...clima });
    }

    return (
        <>
            <Card className='mb-3'>
                <Card.Body>
                    <Row className='align-items-end'>
                        <Col className='col-lg' md="12">
                            <Form.Group controlId="temp">
                                <Form.Label >Temperatura</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="number"
                                        placeholder="Digite a temperatura"
                                        value={temp}
                                        onChange={(e) => setTemp(e.target.value)}
                                    />
                                    <InputGroup.Text id="formTemp">°C</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col className='col-lg' md="12">
                            <Form.Group controlId="hora">
                                <Form.Label>Hora</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="time"
                                        placeholder="Digite o tempo"
                                        value={time}
                                        onChange={(e) => setTime(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col className='col-lg' md="12">
                            <Form.Group controlId="irra">
                                <Form.Label>Irradiação</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="number"
                                        placeholder="Digite a irradiação"
                                        value={irrad}
                                        onChange={(e) => setIrrad(e.target.value)}
                                    />
                                    <InputGroup.Text id="formIrrad">W/m^2</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col lg="auto" md="12">
                            <Button onClick={busca} className='btn btn-falcon-primary mt-lg-0 mt-3'>Aplicar</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row>
                <Col lg="3" xl="2" className='mb-3'>
                    <div className='position-sticky' style={{ 'top': '80px' }}>
                        <NavTecs setTec={setTec} tec={tec} />
                    </div>
                </Col>
                <Col lg="9" xl="10" className='mb-3'>
                    <Charts tec={clima.tec} time={clima.time ?? moment().format('HH:mm')} temp={clima.temp} irrad={clima.ghi} />
                </Col>
            </Row>
        </>
    );
};

export default DashboardAdm;
