import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';


import MainLayout from '../components/app';
import Dashboard from '../components/app/dashboard';
import Erro404 from '../components/errors/404';
import LayoutLogin from '../components/autenticacao/LayoutLogin';
import LayoutDefinedPassHash from '../components/autenticacao/LayoutDefinedPassHash';
import AppContext from '../context/Context';

const Layout = () => {

    const { contextValue } = useContext(AppContext);

    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    const TagHTML = document.getElementsByTagName('html')[0];

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
        if (is.safari()) {
            HTMLClassList.add('safari');
        }
        HTMLClassList.add('fontawesome-i2svg-active');
        HTMLClassList.add('fontawesome-i2svg-complete');

        const theme = localStorage.getItem('data-bs-theme') || 'light';
        TagHTML.setAttribute('data-bs-theme', theme);
        TagHTML.setAttribute('lang', 'pt-BR');
        TagHTML.setAttribute('dir', 'ltr');

    }, [HTMLClassList, TagHTML]);

    if (!contextValue.isAuthenticated) {
        return (
            <>
                <Routes>
                    <Route path="*" element={<LayoutLogin />} />
                </Routes>
            </>
        );
    }

    return (
        <>
            <Routes>
                <Route path="/login" element={<LayoutLogin />} />
                <Route path="/novo-acesso" element={<LayoutDefinedPassHash />} />

                <Route element={<MainLayout />}>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route exact path="/dashboard" element={<Dashboard />} />
                </Route>

                <Route path="*" element={<Erro404 />} />
            </Routes>
        </>
    );

};

export default Layout;
