import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './appCustomStyle.css';
import Layout from './layouts/Layout';
import { ToastContainer } from 'react-toastify';
import 'bootstrap';

import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
import "flatpickr/dist/themes/material_green.css";
flatpickr.localize(Portuguese);

if (!localStorage.getItem('data-bs-theme')) {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        localStorage.setItem('data-bs-theme', 'dark');
    } else {
        localStorage.setItem('data-bs-theme', 'light');
    }
}

const theme = localStorage.getItem('data-bs-theme');
const App = () => {
    return (
        <>
            <Router basename={process.env.PUBLIC_URL}>
                <Layout />
            </Router>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={true}
                pauseOnHover={true}
                theme={theme}
            />
        </>
    );
};

export default App;
