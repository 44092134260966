import React from "react";
import { Link } from "react-router-dom";
import img from "../../components/utils/icon/bg-shape.png";
import img1 from "../../components/utils/icon/shape-1.png";
import LoginForm from "./LoginForm";

const LayoutLogin = props => {

    return (
        <div className="row min-vh-100 flex-center g-0">
            <div className="col-lg-8 col-xxl-7 py-3 position-relative">
                <img src={img} alt="" className="bg-auth-circle-shape" />
                <img src={img1} alt="" className="bg-auth-circle-shape-2" />
                <div className=" p-lg-0 p-4">
                    <div className="card overflow-hidden z-1">
                        <div className="card-body p-0">
                            <div className="row g-0 h-100">
                                <div className="col-md-5 text-center bg-card-gradient d-lg-block d-none">
                                    <div className="position-relative p-4 pt-md-5 pb-md-3" data-bs-theme="light">
                                        <div className="z-1 position-relative">
                                            <Link to={window.location.origin} className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder">
                                                Solar Smart Manager
                                            </Link>
                                            <p className="opacity-75 text-white">
                                            O Solar Smart Manager é uma aplicação desenvolvida como parte de um projeto acadêmico, projetada para prever a geração de energia solar. Utilizando dados climáticos fornecidos pelo usuário, o sistema recomenda a melhor tecnologia de painel solar para maximizar a eficiência energética. Com uma interface intuitiva, o aplicativo simplifica a análise e facilita a tomada de decisões informadas sobre o uso de energia solar, promovendo uma gestão energética mais eficiente e sustentável.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-4 mt-md-4 mb-md-5" data-bs-theme="light">
                                        <p className="text-white d-none">
                                            <Link className="text-decoration-underline link-light">
                                                Contato
                                            </Link>
                                        </p>
                                        <p className="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold text-white opacity-75">
                                            Veja os&nbsp;
                                            <Link className="text-decoration-underline text-white">
                                                Termos
                                            </Link>
                                            &nbsp;e&nbsp;
                                            <Link className="text-decoration-underline text-white">
                                                Condições
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-7 d-flex flex-center flex-wrap">
                                    <div className="text-center bg-card-gradient w-100 d-lg-none">
                                        <Link to={window.location.origin} className="link-light mb-3 mt-4 font-sans-serif fs-4 d-inline-block fw-bolder">
                                            Solar Smart Manager
                                        </Link>
                                    </div>
                                    <div className="p-4 p-md-5 flex-grow-1">
                                        <div className="row flex-between-center">
                                            <div className="col-auto">
                                                <h3>Login</h3>
                                            </div>
                                        </div>
                                        <LoginForm />
                                    </div>
                                    <div className="text-center bg-card-gradient w-100 d-lg-none">
                                        <div className="mt-3 mb-4 mt-md-4 mb-md-5" data-bs-theme="light">
                                            <p className="text-white d-none">
                                                <Link className="text-decoration-underline link-light">
                                                    Contato
                                                </Link>
                                            </p>
                                            <p className="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold text-white opacity-75">
                                                Veja os&nbsp;
                                                <Link className="text-decoration-underline text-white">
                                                    Termos
                                                </Link>
                                                &nbsp;e&nbsp;
                                                <Link className="text-decoration-underline text-white">
                                                    Condições
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default LayoutLogin;