import React, { useEffect } from 'react';
import NavbarDropdownUser from './NavbarTopDropdownUser';

const NavbarTop = () => {



    const TagHTML = document.getElementsByTagName('html')[0];
    const setDark = () => {
        TagHTML.setAttribute('data-bs-theme', 'dark');
        localStorage.setItem('data-bs-theme', 'dark')
    }
    const setLight = () => {
        TagHTML.setAttribute('data-bs-theme', 'light');
        localStorage.setItem('data-bs-theme', 'light')
    }

    useEffect(() => {
        const theme = localStorage.getItem('data-bs-theme') || 'light';
        if (theme === 'dark') {
            document.getElementById('themeControlToggle').setAttribute('checked', true);
        }
    }, []);



    return (
        <nav
            className={'navbar navbar-light navbar-glass navbar-top navbar-expand'}
        >
            <ul className='navbar-nav navbar-nav-icons flex-row align-items-center'>
                <li className='nav-item dropdown'>
                    <h2 className="mb-0 link-primary font-sans-serif fs-4 d-inline-block fw-bolder">Solar Smart Manager</h2>
                </li>
            </ul>
            <ul className='navbar-nav navbar-nav-icons ms-auto flex-row align-items-center'>
                <li className='nav-item dropdown'>
                    <div className='theme-control-toggle fa-icon-wait'>
                        <input className="form-check-input ms-0 theme-control-toggle-input" id="themeControlToggle" type="checkbox" data-theme-control="theme" value="dark" />
                        <label onClick={setDark} htmlFor="themeControlToggle" className='mb-0 theme-control-toggle-label theme-control-toggle-dark'>
                            <span className='fas fa-moon fs-0'></span>
                        </label>
                        <label onClick={setLight} htmlFor="themeControlToggle" className='mb-0 theme-control-toggle-label theme-control-toggle-light'>
                            <span className='fas fa-sun fs-0'></span>
                        </label>
                    </div>
                </li>
                <li className='nav-item dropdown'>
                    <NavbarDropdownUser />
                </li>
            </ul>
        </nav>
    );
};

export default NavbarTop;
