import React, { useContext } from 'react';
// import Home from "../../../utils/img/people1.jpg";
import { Link } from 'react-router-dom';
import AppContext from '../../../../context/Context';
import moment from 'moment/moment';

const NavbarDropdownUser = (props) => {
  const { contextValue, setContextValue } = useContext(AppContext);
  const exp = moment.unix(contextValue?.exp);

  function logout() {
    localStorage.clear();
    setContextValue({ isAuthenticated: false });
  }

  return (
    <>
      <Link className="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div className="avatar avatar-xl">
          <img
            className="rounded-circle"
            src={
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAARhSURBVFiFrZdPbFRVFMZ/572ZKS1FIFCiAduA/JNRowsixTbS9o3EYEVIatxojBoi7EgkMBvTxIUuSAguStAY6sKokABKoglSazvTqgkLY1OCjfinGFtAQNthpp157x4XM44dZoY+2p7NTc79zvd997377j0PfITGnDMab1pXlD/fWqX99ZVF+e4tizTmfO2H2/IDQujB2K8X5VMTu/Gq9xTlA/YrIANzZyCoxxBe0POtVYXGNIhocGpKFQHZhS1H5syAPN51HTjLRLKtYEL1JKonC3J9LQ7wh2w+e9EPd8APCADLvIkn8wuMNXYNFeGU3zHs9c1bKrQ30qlxZ9O0uJFojY5Ea6bFxZ1N2hvpLDUn5QpQjiM0SMO54Xz+5zeWEbReRWU7wmNAKDeVRs2PTCQG8MxBCR+9kK/pa67DWDGE56Xh3He+DORM1LJ48aiET6RV22wurzyAygGguvxSFdLjk3huD+uXbxNpd3WwLcTNm/dOXYgvA3nOq+3VpFKfANumw+ZjcgwyE9eoWPKQrD549U5Q0ZjTjTCAZXXcvnNV2y2GU6eBVt/i/0XyBnjuX0hiuYRPpAt4v29ZQtp6GcyTFq67A8OveOYzjTnnNN4UziMvJ6MzEgeYtxCMuxRr4ed54bhTqzHnQ9IygOoKxOzLvwJVhP5IBGN+k8auIR2J1pA2l4AFMzIAkPoHJschUB2W8NELGm8KYwIbCSQ+lc3fpmDKOSCCwldn88Vp7zWQmYsDVFRlDYg5BGyVhu5BYHAqpPxJqLJ9VuIAVhBEwGTqy0JKaiuS+85nH1YAPHeBaptdajqg/fWVuRstAOaUNHYNMRpdCiZUquDuQwCFocVrgIsaa1kL1g7AxU50lH4Fmta5EQfUZMeAeOXsFdcowvD+FFAxS3X4+8/s+MhHtgjmdkTJJyCCovwwO3EgM5kVt0PjpcTLGsi64PTsDSSzYyAQKwfJG9D++kqNRV7UHudBAELWB8DYjMW9DKSTIKJ41j4A7Yms0XhLJNs15QxovGmd9jqH8OZfAnWwuQUg9719DfSdGYmryd4FAKHKL/PXs+2GUNlP3Lmovc5e7d6ySDTmnAH5hpDpzLVe//Nou8XwxCnQZ+9CHW7dgEwK7NBVwsEVIu9lChD9T63HmD0oD/u8jic+Bn1mem0Pbl0HNw2B0CiV9zwqqw5fuVNJ2U2ofc11OtgWkmXtCWrnbQeilNsTxoPUGIyNgskoFVVfsKHufll1+IoOtoW0r7munM7dtWQj0RpSyX143nMYdxXGs/Ey2Q1nh8YJBGIE2SsPvD80hasWJV6uJSu9+l6fTekvu9fpT7vWT4uLO5s03nLMl3h5Uy0btH/ral+4uFPrl9ffnxGAyLsYd2WBWKxlbfZyKWDciPLWnBrIHU61PNHYdZupnYjsLGRMHgci2vv0tP8Lvg0g7EHoEGkvPM9VMqgUfOPZVkuPI+5Lc2kgTMbtLMrbiQ7sREcx3hxBtdkP9b8do+EGeMEiwgAAAABJRU5ErkJggg=='
            }
            alt=""
          />
        </div>
      </Link>
      <div className="dropdown-menu dropdown-caret dropdown-menu-end py-0">
        <div className="bg-white dark__bg-1000 rounded-2 py-2">
          <button className="dropdown-item" type="button" onClick={logout}>
            <span className="fas fa-sign-out-alt"></span> Sair
          </button>
          <span className="small text-muted dropdown-item d-none">
            {exp.format('DD/MM/YYYY')} às {exp.format('HH:MM:ss')}
          </span>
        </div>
      </div>
    </>
  );
};

export default NavbarDropdownUser;
