import axios from 'axios';
import jwt_decode from 'jwt-decode';

const nameToken = process.env.REACT_APP_NAMETOKEN;
var baseURL = process.env.REACT_APP_BASEURL;

const hostname = document.location.hostname;
const dev = hostname?.indexOf('localhost') > -1;
if (!dev) baseURL = `https://api.${document.location.hostname}`;

var token = localStorage.getItem(nameToken);
const api = axios.create({
  baseURL: baseURL,
});

// Sem acesso ou o token está expirado
const payload = token ? jwt_decode(token) : {};
const agora = new Date();
const timestampUnix = Math.round(agora.getTime() / 1000);
if (!payload?.exp || payload?.exp < timestampUnix) {
  localStorage.clear();
}

api.defaults.headers['Content-Type'] = `application/json; charset=utf-8`;
if (token) api.defaults.headers['authorization'] = `Bearer ${token}`;
export default api;
