import React from "react";
import { Link, useNavigate } from "react-router-dom";


const Erro404 = props => {

    const navigate = useNavigate();

    return (
        <div className="container-fluid">
            <div className="row flex-center min-vh-100 py-6 text-center">
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
                    <Link className="d-flex flex-center mb-4" to={'/'}>
                        <span className="font-sans-serif fw-bolder fs-5 d-inline-block">Solar Smart Manager</span>
                    </Link>
                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <div className="fw-black lh-1 text-300 fs-error">404</div>
                            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">A página que você está procurando não foi encontrada.</p>
                            <hr />
                            <p>
                                Verifique se o endereço está correto e se a página não foi removida. <br />Se você acha que isso é um erro, contate-nos.
                            </p>
                            <button className="btn btn-falcon-primary btn-sm mt-3" onClick={() => navigate(-1)}>
                                <span className="fas fa-reply me-2"></span> Voltar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Erro404;